import React from 'react';
import {
  Slider as DiscreteSlider,
  SliderProps as DiscreteSliderProps,
} from '@mui/material';
import {designSystemToken} from '@lightricks/react-design-system';

interface SliderProps extends DiscreteSliderProps {
  testID?: string;
  sliderRef?: React.RefObject<HTMLDivElement> | null;
}

function Slider(props: SliderProps) {
  const {testID = 'slider', sliderRef, sx, ...rest} = props;

  return (
    <DiscreteSlider
      data-testid={testID}
      ref={sliderRef}
      marks
      disabled
      sx={{
        'marginTop': '4px',
        'marginBottom': '16px',
        '& .MuiSlider-rail': {
          backgroundColor: designSystemToken('semantic.bg.neutral-inverse'),
          height: '2px',
        },
        '& .MuiSlider-track': {
          backgroundColor: designSystemToken('semantic.fg.primary'),
          height: '2px',
          border: 0,
        },
        '& .MuiSlider-thumb': {
          'width': '18px',
          'height': '18px',
          'color': '#ffffff',
          'outline': '2px solid #ffffff',
          'border': '2px solid #000000',
          'padding': '2px',
          '&::before': {
            boxShadow: 'none',
          },
        },
        '& .MuiSlider-mark': {
          'backgroundColor': designSystemToken('semantic.bg.neutral-inverse'),
          'height': '8px',
          'width': '2px',
          'marginTop': 0,
          '&.MuiSlider-markActive': {
            backgroundColor: designSystemToken('semantic.fg.primary'),
          },
        },
        '&.Mui-disabled': {
          'color': designSystemToken('semantic.bg.neutral-inverse'),
          '& .MuiSlider-track': {
            backgroundColor: designSystemToken('semantic.bg.neutral-inverse'),
            height: '2px',
          },
          '& .MuiSlider-mark': {
            'backgroundColor': designSystemToken('semantic.bg.neutral-inverse'),
            'height': '8px',
            'width': '2px',
            'marginTop': 0,
            '&.MuiSlider-markActive': {
              backgroundColor: designSystemToken('semantic.bg.neutral-inverse'),
            },
          },
          '& .MuiSlider-thumb': {
            width: '18px',
            height: '18px',
            color: '#ffffff',
            outline: '2px solid #ffffff',
            border: `2px solid ${designSystemToken(
              'semantic.bg.neutral-inverse'
            )}`,
            padding: '2px',
          },
        },
        ...sx,
      }}
      {...rest}
    />
  );
}

export default Slider;
