import {useMutation} from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import queryClient from '@/utils/reactQueryClient';
import {CreatorBrandSafetyVetting} from '@/types/creatorBrandSafetyVetting';
import vettingUpdaters from '@/api/updaters/vetting';
import {queryKey as resultsQueryKey} from '@/hooks/queries/use-creator-brand-safety-vettings-query';

dayjs.extend(utc);

function useRegenerateBrandSafetyReport({brandId}: {brandId: string}) {
  const mutationKey = ['vetting', 'regenerate'];

  return useMutation({
    mutationKey,
    mutationFn: vettingUpdaters.regenerate,
    onMutate: async (data) => {
      const previousData = queryClient.getQueryData([resultsQueryKey, brandId]);

      queryClient.setQueryData(
        [resultsQueryKey, brandId],
        (old: {data: CreatorBrandSafetyVetting[]} | undefined) => {
          if (!old?.data) return old;

          const existingVetting = old.data.find(
            (item) => item.id === data.creator.id
          );
          if (!existingVetting) return old;

          const updatedData = old.data.map((item) =>
            item.id === data.creator.id
              ? {...item, reportStatus: 'scraping' as const}
              : item
          );

          return {...old, data: updatedData};
        }
      );

      return previousData;
    },
    onError: (_err, _data, context) => {
      queryClient.setQueryData([resultsQueryKey, brandId], context);
    },
    onSettled: () => {
      queryClient.invalidateQueries([resultsQueryKey, brandId]);
    },
  });
}

export default useRegenerateBrandSafetyReport;
