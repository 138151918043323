import {
  CreatorBrandSafetyVetting,
  type CreatorBrandSafetyVettingStatus,
} from '@/types/creatorBrandSafetyVetting';
import {VettableCreator} from '@/types/vettableCreatorsResponse';
import api from '../index';

type VettingUpdaterArgs = {
  status: {
    vettingId: string;
    status: CreatorBrandSafetyVettingStatus;
  };
  create: {
    creator: VettableCreator;
    brandId: string;
  };
  regenerate: {
    creator: CreatorBrandSafetyVetting;
    brandId: string;
  };
};

const vettingUpdaters = {
  status: async ({vettingId, status}: VettingUpdaterArgs['status']) => {
    return api.put(
      `/creator_brand_safety_vettings/${vettingId}/update_status`,
      {status}
    );
  },
  create: async ({creator, brandId}: VettingUpdaterArgs['create']) => {
    return api.post(
      `/creator_brand_safety_vettings`,
      {profile_id: creator.id, invite_only: creator.inviteOnly},
      {
        params: {brand_id: brandId},
      }
    );
  },
  regenerate: async ({creator, brandId}: VettingUpdaterArgs['regenerate']) => {
    return api.post(
      `/creator_brand_safety_vettings`,
      {profile_id: creator.profile.id},
      {
        params: {brand_id: brandId},
      }
    );
  },
};

export default vettingUpdaters;
