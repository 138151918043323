export default {
  genders: {
    all: 'All',
    male: 'Male',
    female: 'Female',
    other: 'Other',
  },
  views: {
    'auth': {
      signup: {
        'title': 'Explore the platform for free',
        'alt-title': 'Let’s start! Create your account',
        'marketing-experiment-title': 'Create your account',
        'subtitle': 'Start planning your first campaign today.',
        'login-link-text': 'Already have an account?',
        'login-link-button': 'Log in',
        'terms':
          'Brand is responsible for creator costs plus applicable service fees. All amounts shown are in USD. Signing up for an account allows you to review creator profiles and portfolios.',
        'invalid-business-email':
          'Please enter a valid business email, e.g. joe@mochajoes.com',
        'invalid-business-email-title': 'Invalid email',
        'general-error': 'Something went wrong',
        'general-error-message': 'Please enter your details and try again',
      },
    },
    'ai-content-generator': {
      dashboard: {
        'generate-button': 'Generate',
        'exclusions': 'Exclusions',
        'prompt-input-placeholder':
          'Placement, location, details, style, colors, mood...',
        'negative-prompt-input-start': 'Exclude',
        'negative-prompt-input-placeholder': 'Unwanted details, styles...',
        'example-prompt':
          'Example: Product placed on a rustic wooden table, sandy beach in the background, seashells scattered around, gentle waves, summer vibes, professionally shot, high quality',
        'thumbnail': {
          'tooltip-title': 'Replace',
        },
        'format-selector': {
          'tooltip-title': 'Change format',
        },
        'errors': {
          'general-error-title': 'Something went wrong',
          'general-error-message':
            'Please try generating again or come back later',
        },
      },
      components: {
        'your-product': {
          title: 'Your product',
          content: 'Upload a new product image',
          button: 'Replace product',
        },
        'aspect-ratio': {
          title: 'Aspect ratio',
        },
        'style-strength': {
          title: 'Style strength',
        },
        'style-reference': {
          title: 'Style reference',
          content:
            'Upload a photo in the style of the final image you want to generate or choose from one of ours.',
          tooltip:
            'Note that uploading a style reference image will override your text prompt.',
        },
        'image-viewer': {
          'save-to-library': {
            'title': {
              'already-saved': 'Image saved to library',
              'save-to-library': 'Save to library',
            },
            'tooltip-title': {
              'already-saved': 'Image already saved to library',
              'save-to-library': 'Save to library',
            },
            'success': 'Image added to your content library',
            'already-exist': 'Image already exists in your content library',
            'add-image-error':
              'There was an error when adding the image to your content library',
          },
          'download': {
            'title': 'Download image',
            'tooltip-title': 'Download image',
          },
        },
        'image-uploader': {
          'errors': {
            'file-size-message':
              'Your image exceeds the maximum allowed size. Please upload a smaller image to continue.',
            'file-type-message':
              'This file type is not supported. Try jpg or png instead.',
            'general-error-message': 'Please try again later.',
            'general-error-title': 'Something went wrong',
          },
          'aria-label': 'Upload an image',
          'input-validations': {
            'empty-description-message': 'Describe your product to continue.',
            'image-processing-message':
              'We are processing your image. This will just take a moment.',
            'image-upload-message': 'Upload an image to continue.',
          },
          'lightricks-legal-terms': {
            policy: 'Privacy Policy',
            platform: 'Platform Terms and Conditions',
            ai: 'AI Terms of Use',
          },
          'upload-box': {
            'empty-state': {
              text: 'Click or drop here to upload',
            },
            'replace-button': {
              text: 'Replace',
            },
          },
          'image-description': {
            'label': 'This product is a:',
            'input-placeholder': 'Bottle of wine, lipstick, food',
          },
          'upload-image-instructions': {
            title: 'For best results',
            text: 'Upload a high quality, well-lit image of your product resting in the center of a plain background, with no other objects in the frame.',
          },
        },
        'welcome-frame': {
          'title': 'Welcome to AI Studio',
          'subtitle':
            'Upload your product image and instantly start generating studio quality product images<br/>' +
            "for your brand's website, social media, storefront, and more.",
          'button-label': 'Let’s  go',
        },
      },
    },
    'campaigns': {
      'brief': {
        builder: {
          'campaign-saved-to-drafts': 'Campaign saved to drafts',
        },
      },
      'brief-field-manager': {
        titles: {
          'ideal-creator': 'Ideal creator',
          'product-to-feature': 'Product to feature',
          'content-mandatories': 'Content Mandatories',
          'posting-instructions': 'Posting instructions',
          'deliverables': 'Deliverables',
          'timeline': 'Timeline overview',
          'content-agreement': 'Content agreement',
          'terms-and-conditions': 'Terms and conditions',
          'brand-style-guide': 'Brand style guide',
          'eligibility-requirements': 'Eligibility requirements',
          'campaign-hashtag': 'Campaign hashtag',
          'caption-guidelines': 'Caption guidelines',
        },
      },
      'components': {
        'wizard': {
          steps: {
            'budget-and-network': {
              'label': 'Select budget and network',
              'budget-title': 'What’s your campaign’s budget?',
              'budget-subtitle':
                'Select a budget to guide us in creating your brief. No charges will apply at this moment.',
              'network-label': 'Where will your content be posted?',
              'network-instagram': 'Instagram',
              'network-tiktok': 'TikTok',
              'network-youtube': 'Youtube',
              'custom-budget-placeholder': 'Custom amount',
              'brand-name-label': 'Brand name',
              'brand-name-info':
                'This field is only visible in the staging environment and is required for testing purposes as the brand name is used as a search parameter for the AI',
              'brand-name-placeholder': 'What is the brand name?',
            },
            'deliverables': {
              'awareness-deliverables': {
                'label': 'Select deliverables',
                'deliverables-title': 'Add deliverables',
                'deliverables-subtitle':
                  'Add types of content influencers should produce and post for your campaign',
                'deliverable-feed-post': 'Feed post',
                'deliverable-story': 'Story: 3+ frames',
                'deliverable-reel': 'Reel',
                'deliverable-add-post': 'Add post',
                'deliverable-tiktok-post': 'TikTok post',
                'deliverable-post': 'Post',
                'deliverable-type-photo': 'Image',
                'deliverable-type-video': 'Video',
                'deliverable-type-story': 'Image or video',
                'deliverable-short-form-video': 'Short video',
                'deliverable-long-form-video': 'YouTube video',
                'deliverable-shorts': 'Shorts',
                'deliverable-type-short-form-video': '30s or less',
                'deliverable-type-long-form-video': '30s - 5min',
                'deliverable-type-shorts': '60s or less',
              },
              'content-deliverables': {
                'deliverables-subtitle':
                  'Add any additional assets you would like produced for your campaign',
                'deliverables-subtitle-content-only':
                  'Add assets you would like produced for your campaign',
                'deliverable-standard-photo': 'Photo',
                'deliverable-standard-video': 'Video',
                'deliverable-premium-photo': 'Premium photo',
                'deliverable-premium-video': 'Premium video',
                'deliverable-type-photo': 'Image',
                'deliverable-type-video': 'Video',
                'deliverable-type-ugc': 'UGC',
                'deliverable-type-studio-look-and-feel': 'Studio look & feel',
              },
            },
            'influencer-package': {
              'label': 'Creator package',
              'influencer-package-title': 'Choose your influencer type',
              'influencer-package-subtitle':
                'Opt for a smaller follower count to collaborate with more influencers, or choose to work with fewer influencers with a larger following.',
            },
            'product': {
              'label': 'Your product',
              'product-label': 'Describe your product',
              'product-input-placeholder': 'Saya cold brew, Moon yoga mat...',
              'send-product-to-creators': {
                'send-product-to-creators-label':
                  'Sending your product to creators',
                'send-product-to-creators-description':
                  'Decide if and how you’ll be sending product samples',
                'not-relevant': 'Not relevant',
                'not-relevant-info':
                  'Creators will be asked to purchase the product',
                'discount-code': 'Promo code',
                'discount-code-info':
                  'Creators will be asked to purchase the product using a code provided',
                'ship-product': 'Ship product',
                'ship-product-info':
                  'Creators will receive one or more items to feature',
                'cash-value-label': 'Product value:',
                'cash-value-placeholder': 'Product cash value',
              },
              'continue-button-label': 'Done',
            },
            'guidelines': {
              'label': 'Campaign guidelines',
              'guidelines-label': 'Campaign guidelines',
              'guidelines-label-info':
                'This is an optional field for you to outline your goals, visual style, tone of\nvoice, and unique selling points. Or leave it blank and we will write it for you.',
            },
            'done': {
              'label': 'Done!',
              'info':
                'Machine-generated content may contain errors.<br/>Please read the <a href="https://static.lightricks.com/legal/BriefAI.pdf" target="_blank"><b>AI disclaimer</b></a> before generating your brief.',
              'generate-brief': 'Generate brief',
            },
          },
          errors: {
            'budget':
              'This budget is too low. Please input a budget of $1000 or higher.',
            'required-deliverables-budget':
              'The number of deliverables added exceeds your budget. Delete deliverables to continue.',
            'required-deliverables-limit':
              'You have selected the maximum number of deliverables.',
          },
        },
        'launch-campaign-modal': {
          'campaign-is-live': {
            'title': 'Your campaign is live!',
            'subtitle': 'You can now start inviting creators to work with you.',
            'primary-button': 'Invite creators',
          },
          'free': {
            'title': 'Are you sure you want to launch?',
            'subtitle':
              'Subscribe to the platform and your campaign will go live.',
            'primary-button': 'Subscribe and launch',
            'secondary-button': 'Go back to edit',
          },
          'essentials': {
            'title': 'Are you sure you want to launch?',
            'subtitle':
              'Once you click launch creators will start seeing your campaign and be able to apply.',
            'primary-button': 'Launch campaign',
            'secondary-button': 'Go back to edit',
          },
          'enterprise-and-platform': {
            'title': 'All done!',
            'secondary-title': 'We’ll review your brief within 2 working days.',
            'subtitle':
              'In the meantime, check out our creator community and start adding to your list.',
            'primary-button': 'Discover creators',
            'secondary-button': 'How are briefs reviewed?',
          },
        },
        'brief-item': {
          optional: 'Optional',
        },
        'brief-field-helper': {
          'title': 'Help me write',
          'placeholder': 'Type your request',
          'error-message':
            'Something went wrong. If the problem persists, please contact our <a href="https://popularpays.zendesk.com/hc/en-us/requests/new" rel="noopener noreferrer" target="_blank">support team</a>.',
        },
        'brief-header': {
          'placeholder-campaign-name': 'New brief',
          'breadcrumbs': {
            'my-campaigns': 'My campaigns',
            'new-campaign': 'New campaign',
          },
        },
      },
      'scraping-website-message':
        'Let me take a look at the website you provided...',
    },
    'home': {
      'creator-search': {
        label: 'Search Creators',
      },
      'next-steps': {
        'label': 'Pick up where you left off',
        'complete-brief': {
          title: 'Complete brief',
          description: 'Only a few more steps to go',
        },
        'view-campaigns': {
          title: 'See your campaigns',
          description: 'Action items to review',
        },
      },
      'what-is-new': {
        label: 'What’s new?',
      },
      'workflows': {
        label: 'Start here',
        content: [
          {
            title: 'Start Campaign',
            description:
              'Influencers will share your product on their social media channels',
            button: 'Create',
          },
          {
            title: 'Search Creators',
            description:
              'Creators will produce photos & videos for your brand’s online presence',
            button: 'Create',
          },
          {
            title: 'AI Studio',
            description: 'Generate inspiring content',
            button: 'Create',
          },
        ],
      },
      'talent-matches': {
        'label': 'Creators for you',
        'view-all': 'See more',
      },
      'components': {
        'creator-search-card': {
          'title': 'Most popular creator categories',
          'placeholder': 'Search by name, handle, keywords',
          'placeholder-short': 'Search',
          'tag-menu-title': 'Most popular',
        },
        'explore-categories': {
          'title': 'Explore categories',
          'sub-categories': {
            'popular-categories': 'Popular categories',
          },
        },
      },
      'ai-studio-card': {
        'title': 'AI Studio',
        'description':
          "Easily generate studio quality product images for your brand's website, social media, storefront, and more.",
        'button-label': 'Try It Now',
      },
    },
    'under-maintenance': {
      body: "We're currently performing some essential updates to improve your experience. Our web app will be back online shortly. Thank you for your patience!",
      title: 'Temporary Maintenance',
    },
    'creators': {
      'auto-filters': {
        title: 'Auto-Filters Applied',
        content:
          'Brief requirements set to your needs. Press clear all to remove',
      },
      'email-copied': 'Email copied to clipboard',
      'creator-added-to-list': 'Added to list {listName}',
      'creator-added-to-list-button': 'View list',
      'title': 'Search Creators',
      'empty-state': 'No matches found',
      'initial-loading-error': 'Something went wrong',
      'pagination': '{currentPage} - {totalPages} pages',
      'components': {
        'table': {
          columns: {
            'name': 'Creator Name',
            'engagement-rate': 'Eng Rate',
            'followers': 'Follower count',
            'social-networks': 'Social Networks',
            'instagram-followers': 'Instagram Followers',
          },
        },
        'actions-drawer': {
          'button-clear-all': 'Clear All',
          'button-apply': 'Apply',
          'title': {
            'category': 'Category',
            'creator-details': 'Creator Details',
            'platforms': 'Platforms',
            'audience': 'Audience',
            'keywords': 'Keywords',
            'columns': 'Columns',
          },
          'filters': {
            'category': 'Category',
            'gender': 'Gender',
            'audience-gender': 'Audience Gender',
            'age': 'Age',
            'audience-age': 'Audience Age',
            'age-placeholder': 'Select an Age',
            'location': 'Location',
            'audience-location': 'Audience Location',
            'location-placeholder': 'Select a country, state and city',
            'platform': 'Platform',
            'platform-metrics': 'Creator Platform Metrics',
            'platform-tooltip':
              '* If a profile has Instagram demographics, those will be utilized within the search.\n* If a profile has Instagram demographics, those will be utilized within the search.\n* And so on by the order above: Youtube, Twitter, Facebook, Pinterest.',
            'followers': 'Followers',
            'subscribers': 'Subscribers',
            'average-views': 'Avg Views',
            'engagement-rate': 'Engagement rate',
            'platform-all': 'All',
            'platform-instagram': 'Instagram',
            'platform-tiktok': 'Tiktok',
            'platform-youtube': 'Youtube',
            'platform-facebook': 'Facebook',
            'platform-twitter': 'Twitter',
            'platform-pinterest': 'Pinterest',
            'keywords': 'Find a keyword',
            'keywords-tooltip':
              'Topics (Find influencers by the topics they post about. Topics are groups of related hashtags or by a phrase they used within the caption of their post.',
            'keywords-placeholder': 'Music',
            'weight-placeholder': 'Select percentage',
            'weight-placeholder-short': '%',
            'age-lda-description': '(Audience is 21 or older)',
            'engage-rate-options': {
              1: 'Higher than 1%',
              2: 'Higher than 2%',
              3: 'Higher than 3%',
              4: 'Higher than 4%',
              5: 'Higher than 5%',
              6: 'Higher than 6%',
              7: 'Higher than 7%',
              8: 'Higher than 8%',
              9: 'Higher than 9%',
              10: 'Higher than 10%',
              11: 'Higher than 11%',
              12: 'Higher than 12%',
              13: 'Higher than 13%',
              14: 'Higher than 14%',
              15: 'Higher than 15%',
              16: 'Higher than 16%',
              17: 'Higher than 17%',
              18: 'Higher than 18%',
              19: 'Higher than 19%',
              20: 'Higher than 20%',
            },
            'age-options': {
              '13-17': '13-17',
              '18-24': '18-24',
              '25-34': '25-34',
              '35-44': '35-44',
              '45-64': '45-64',
              '65-plus': '65+',
              'lda': 'LDA',
            },
            'gender-options': {
              male: 'Male',
              female: 'Female',
              other: 'Other',
              all: 'All',
            },
            'average-views-marks': {
              low: 'Low',
              high: 'High',
            },
            'followers-marks': {
              nano: 'Nano',
              micro: 'Micro',
              mid: 'Mid',
              max: 'Max',
            },
            'age-marks': {
              '13': '13',
              '18': '18',
              '25': '25',
              '35': '35',
              '45': '45',
              '65-plus': '65+',
            },
            'range-input-min': 'Minimum',
            'range-input-max': 'Maximum',
            'bio': 'Bio',
            'bio-placeholder': 'Search in bio',
          },
        },
        'search-input': {
          placeholder: 'Search',
        },
        'creators-actions-bar': {
          'button-clear-all': 'Clear Selection',
          'creators-selected_one': 'Creator selected',
          'creators-selected_other': 'Creators selected',
        },
        'action-drawer-buttons': {
          'filter-by': 'Filter by:',
          'clear-all': 'Clear All',
        },
        'creator-action': {
          'success-button': 'Got it',
          'success-title': 'Success',
          'add-to-my-creators': {
            'tooltip': 'Add to list',
            'title': 'Add to list',
            'dropdown-placeholder': 'Select list',
            'new-list': 'New list',
            'new-list-name-validation':
              'List name must be under {count} characters.',
            'submit-button': 'Add',
            'submit-button-save': 'Save',
            'back-button': 'Back',
            'group-title-shared': 'Shared lists',
            'group-title-restricted': 'Restricted lists',
            'shared-lists-checkbox':
              'Anyone in your organization can view this list.',
            'organization-visibility':
              'Anyone in your organization can view this list.',
          },
          'remove-from-my-creators': {
            bulkLabel: '{count} creators',
            title: 'Remove {resourceLabel}?',
            body: 'Deleting a creator will remove them from all lists. Are you sure you want to remove them?',
            cancel: 'Cancel',
            remove: 'Remove',
          },
          'add-to-campaign': {
            'tooltip': 'Invite to campaign',
            'title': 'Invite to campaign',
            'dropdown-placeholder': 'Choose campaign',
            'invite-action-warning':
              "Click 'Invite' to send out your campaign invitation via email. Just a heads-up, <b>this step is final and can't be taken back.</b>",
            'submit-button': 'Invite',
            'success-title': 'Invitation sent!',
            'success-subtitle':
              "We'll notify you when there's an\nupdate. No need to wait.",
          },
          'share-creator': {
            'tooltip': 'Share',
            'title': 'Share',
            'submit-button': 'Copy Link',
          },
          'invite-creator': {
            'tooltip': 'Message',
            'title': 'Invite creator',
            'submit-button': 'Send',
            'success-title': 'Message sent',
            'success-subtitle':
              "We'll notify you when there's an\nupdate. No need to wait.",
          },
          'remove-creator': {
            'tooltip': 'Remove',
            'success-title_one': 'The creator has been removed',
            'success-title_other': 'The {count} creators have been removed',
          },
          'message-creator': {
            'tooltip': 'Message',
            'bulk-tooltip': 'Bulk message (coming soon)',
            'title':
              'Start a thread expressing your interest in content or posts.',
            'message-placeholder':
              'Start connecting! Share your interests and what you envision for your project.',
            'notice-label': 'Off-Platform Payment',
            'off-platform-payment':
              'Any agreements to compensation that are made through Threads are not issued through Popular Pays and are the responsibility of the brand.',
            'sending-as': 'Sending as {brandName}',
            'send': 'Send',
          },
          'message-sent-notification': {
            'headline': 'Message sent',
            'message': `We'll notify you when there's an update. No need to wait.`,
            'got-it': 'Got it',
          },
        },
        'no-active-campaigns-modal': {
          'title': 'No active campaign yet.',
          'subtitle':
            'You can save these creators to a list for later or start a new campaign now.',
          'save-to-list-button': 'Save to List',
          'create-campaign-button': 'Create Campaign',
        },
      },
      'flash-messages': {
        'invite-to-campaign-title_one': 'Creator has been invited',
        'invite-to-campaign-title_other': 'Creators have been invited',
        'message-creator-title': 'Message sent',
        'banner-message': `"We'll notify you when there's an\nupdate. No need to wait."`,
        'banner-button': 'Got it',
      },
      'search': {
        components: {
          'search-controls': {
            sorting: {
              'menu-title': 'Sort by',
              'options': {
                'engagement-rate': 'Eng. Rate',
                'followers': 'Followers',
                'relevancy': 'Best Match',
                'rating': 'Rating',
              },
            },
          },
        },
      },
    },
    'list-creators': {
      'title': 'My creators',
      'search': {
        placeholder: 'Search username',
      },
      'button': {
        'invite-external-creators': 'Invite external creators',
      },
      'empty-state': {
        'no-lists': 'No lists yet',
        'no-lists-description': 'Start saving creators to see them listed here',
        'button': 'Explore creators',
        'no-creators': 'No creators yet',
        'no-creators-description':
          'Begin curating your list of favorite creators',
      },
    },
    'public-profile': {
      upsell: {
        1: 'Are you a brand that wants to collaborate with {displayName}?',
        2: 'Log In',
        3: ' or',
        4: 'Sign Up',
        5: ' to unlock a full profile, detailed stats and more.',
      },
    },
    'shared-list': {
      'powered-by': 'POWERED BY',
    },
  },
  components: {
    'auth': {
      'signup-form': {
        'firstname-label': 'First Name',
        'lastname-label': 'Last Name',
        'email-label': 'Business email',
        'brand-name-label': 'Brand Name',
        'test-brand-label':
          'This a test brand. This helps us know what accounts are real and what accounts are for testing purposes in production.',
        'submit-button': 'Create your account',
        'terms-label':
          "I agree to the <a target='_blank' href='https://popularpays.com/platform-terms/' class='color--gray-medium-dark'>Popular Pays Terms</a> and the creator payment terms. Learn about how we use and protect your data in our <a target='_blank' href='https://static.lightricks.com/legal/privacy-policy.pdf' class='color--gray-medium-dark'>Privacy Policy</a>",
        'errors': {
          'invalid-business-email':
            'Please enter a valid business email, e.g. joe@mochajoes.com',
          'brand-name': 'Please enter your brand name',
          'brand-name-exist-for-events':
            'Brand name is already in use. Submit a request to claim your account',
          'brand-name-exist': {
            'first-plain-text': 'Brand name is already in use. ',
            'link-text': 'Submit a request',
            'second-plain-text': ' to claim your account',
            'link-url': 'https://popularpays.zendesk.com/hc/en-us/requests/new',
          },
          'email': 'Please enter your employee email address',
          'email-invalid': 'Please enter a valid email address',
          'email-exist-for-events':
            'Email is already in use. Submit a request to claim your account',
          'email-exist': {
            'first-plain-text': 'Email is already in use. ',
            'link-text': 'Submit a request',
            'second-plain-text': ' to claim your account',
            'link-url': 'https://popularpays.zendesk.com/hc/en-us/requests/new',
          },
          'first-name': 'Please enter your first name',
          'last-name': 'Please enter your last name',
          'terms':
            'Please confirm that you have read and agree to the Terms of use and Privacy policy',
        },
      },
      'otp-verification-modal': {
        'title': 'A code was sent to your email',
        'email-label': 'Enter it below to verify this address:',
        'resend-code': 'Resend code',
        'resend-code-success': 'Code was sent to your email.',
        'continue': 'Continue',
      },
    },
    'menu': {
      'items': {
        'home': 'Home',
        'search': 'Search',
        'ai-content-creator': 'AI Content Creator',
        'creators': 'My Creators',
        'collaborations': 'Threads',
        'campaigns': 'My Campaigns',
        'ai-content-generator': 'AI Studio',
        'content-library': 'Content Library',
        'stats': 'Stats',
        'powered-by': 'Powered by Pop Pays',
        'bca-partnerships': 'Branded Content',
        'subscribe': 'Subscribe',
        'brief-ai': 'Brief AI',
        'ai-studio': 'AI Studio',
        'safe-collab': 'SafeCollab',
      },
      'tooltips': {
        'renew-subscription': 'Renew subscription to access',
        'access-expired': 'Access Expired',
        'upgrade-to-access': 'Upgrade to Access',
      },
      'new-campaign': 'New Campaign',
      'subscribe-container': {
        'launch-campaigns': 'Launch campaigns',
        'send-threads': 'Send threads',
        'more-ai-content': 'More AI content',
      },
    },
    'report-inappropriate-content': {
      'title-report-url':
        'Copy & paste the URL of the content you want to report.',
      'subtitle-report-content': 'Why are you reporting this?',
      'placeholder-something-else': 'Let us know...',
      'report-done': {
        title: 'Thanks for letting us know',
        subtitle: 'Your feedback helps us keep the community happy and safe.',
      },
      'report-content-email-message':
        'Hello,\nI wanted to report inappropriate content I came across at the following URL:{url}\nThe type of report is: {typeReport}',
      'report-content-email-subject': 'Report inappropriate content',
    },
    'brand-header': {
      'placeholder': 'search brand..',
      'campaigns': {
        'title': 'Campaigns',
        'shortlisted-reach': 'Shortlisted Reach',
        'accepted-reach': '{reachLabel}',
        'shortlisted-cost': 'Shortlisted Cost, USD',
        'accepted-cost': 'Total Cost, USD',
      },
      'tooltips': {
        'shortlisted-reach':
          'The sum of followers per post for shortlisted creators.',
        'accepted-reach':
          'The sum of followers per post for accepted creators.',
        'shortlisted-cost':
          'The total dollar amount of shortlisted creator bids for this campaign.',
        'accepted-cost':
          'The total cost of accepted creators for this campaign. This amount has already been deducted from the brand budget.',
      },

      'child-components': {
        'tooltips': {
          'shortlisted-reach':
            'The sum of followers per post for shortlisted creators.',
          'accepted-reach':
            'The sum of followers per post for accepted creators.',
          'shortlisted-cost':
            'The total dollar amount of shortlisted creator bids for this campaign.',
          'accepted-cost':
            'The total cost of accepted creators for this campaign. This amount has already been deducted from the brand budget.',
        },
        'campaigns': {
          'title': 'Campaigns',
          'shortlisted-reach': 'Shortlisted Reach',
          'accepted-reach': '{reachLabel}',
          'shortlisted-cost': 'Shortlisted Cost, USD',
          'accepted-cost': 'Total Cost, USD',
        },
        'search': {
          title: 'Search Creators',
        },
        'creators': {
          title: 'My Creators',
        },
        'collaborations': {
          title: 'Threads',
        },
        'content-library': {
          title: 'Content Library',
        },
        'stats': {
          title: 'Stats',
        },
        'bca-partnerships': {
          title: 'Branded Content',
        },
        'campaign': {
          'placeholder-campaign-name': 'New brief',
          'breadcrumbs': {
            'my-campaigns': 'My campaigns',
            'new-campaign': 'New campaign',
          },
        },
        'account': {
          title: 'Account Settings',
        },
        'organization-settings': {
          title: 'Organization Settings',
        },
      },
    },
    'new-campaign-button': {
      label: 'New Campaign',
    },
    'create-new-button': {
      label: 'Create New',
    },
    'popular-pays-footer': {
      label: 'Popular Pays By Lightricks',
    },
    'stepper': {
      next: 'Next',
      complete: 'Complete',
      back: 'Back',
    },
    'basic-stepper': {
      'next-button-label': 'Next',
      'complete-button-label': 'Complete',
      'back-button-label': 'Back',
    },
    'stepper-progress': {
      indicator: `{activeStep} of {steps}`,
    },
    'onboarding-questionnaire': {
      'welcome': 'Welcome to Popular Pays 👋',
      'welcome-back': 'Welcome back to a new Popular Pays 👋',
      'welcome-description':
        'Please tell us a bit about yourself. Your responses are important to us! We will use them to personalize your experience.',
    },
    'arrows-navigation': {
      of: 'of',
    },
    'not-found': {
      'body': "You don't have to go home, but you can't click here.",
      'error-code': '404',
      'home-link': 'Take me home',
      'page-title': 'Not Found',
    },
    'subscription-expiration-banner': {
      'main-text': {
        'time-frame': '(1)[one day];(2-inf)[{count} days];',
        'campaign': 'Your platform access expires in {timeFrame}.',
        'default':
          'Your subscription expires in {timeFrame} and you will lose platform access.',
      },
      'contact-subject': {
        platform:
          "My Platform Subscription is expiring and I don't want it to!",
        campaign:
          "My Campaign Subscription is expiring and I don't want it to!",
      },
      'contact-text-first': 'Contact us',
      'contact-text-last': 'to renew.',
    },
    'campaign-brief-link': {
      'open-brief-link': 'Open brief',
    },
    'upgrade-button': {
      label: 'Upgrade',
    },
    'user-menu': {
      'settings-label': 'Settings',
      'account-settings': 'Account settings',
      'organization-settings': 'Organization settings',
      'help-label': 'Help',
      'getting-started': 'Getting Started',
      'contact-sales': 'Talk to sales',
      'support': 'Support',
      'report': 'Report inappropriate content',
      'report-mobile': 'Report',
      'logout': 'Log out',
    },
    'mobile-not-supported-placeholder': {
      'title': 'AI Studio',
      'subtitle':
        "Sorry, we can't display this feature on<br/>this lightweight mobile page.",
      'subtitle-bold': 'Experience the full feature on desktop.',
    },
    'campaign-brief': {
      'sections': {
        'basic-details': {
          'title': 'General details',
          'campaign-name': 'Campaign title',
          'campaign-name-placeholder': 'Name your campaign',
          'campaign-verticals': 'Brand verticals',
          'campaign-verticals-placeholder': 'Select brand verticals',
          'campaign-verticals-add-button': 'Add vertical',
          'about-your-brand-and-campaign': 'About your brand and campaign',
          'about-your-brand-and-campaign-placeholder': 'Describe your campaign',
          'campaign-image-title': 'Campaign Image',
          'campaign-image-description': 'Click or drag a file',
          'replace-image': 'Replace',
        },
        'creator-payments': {
          'title': 'Creator payments',
          'creator-compensation': 'Creator compensation',
          'creator-compensation-info':
            "This is how much you're willing to pay each creator for their work. Please note that all creator fees will subject to an additional service fee. Read our <a target='_blank' href='https://popularpays.zendesk.com/hc/en-us/articles/4417904312980-Pricing-Creator-Bids-and-fees'>guide to compensating creators.</a>",
          'suggested-range': 'Suggested range',
          'suggested-range-info':
            'Set a guideline and creators will propose a fee for their work.',
          'fixed-price': 'Set a fixed price',
          'fixed-price-info':
            'All creators will be paid a set fee for their work. Be aware that non-negotiable fees may dissuade creators from applying.',
          'min': 'Minimum compensation',
          'max': 'Maximum compensation',
          'product-details': 'Providing the product to creator',
          'product-details-info':
            'Decide if and how you’ll be sending product samples',
          'not-relevant': 'Not relevant',
          'not-relevant-info': 'Creators will be asked to purchase the product',
          'discount-code': 'Promo code',
          'discount-code-info':
            'Creators will be asked to purchase a product using the code provided',
          'ship-product': 'Ship product',
          'ship-product-info':
            'Creators will receive one or more items to feature',
          'cash-value': 'Product cash value',
          'currency': 'USD',
        },
        'deliverables': {
          'title': 'Deliverables',
          'caption-guidelines': {
            'title': 'Captions, mentions and hashtags',
            'add-button': 'Add',
            'options': {
              caption: 'Caption',
              hashtag: 'Hashtag',
              mention: 'Mention',
            },
          },
          'add-deliverable': 'Add deliverable',
          'deliverable': {
            'deliverable-placeholder':
              'Provide a detailed description of the expected deliverable',
            'deliverable-type-story_post': 'Instagram Story',
            'deliverable-type-feed_post_photo': 'Instagram Feed Post',
            'deliverable-type-reel_post': 'Instagram Reel',
            'deliverable-type-tiktok_video': 'TikTok Post',
            'story_post': '1 story uploads - {count} Story frames',
            'feed_post_photo': '1 photo',
            'reel_post': '1 video',
            'tiktok_video': '1 video',
            'deliverable-type-content_standard_photo': 'Photo',
            'deliverable-type-content_standard_video': 'Video',
            'deliverable-type-content_premium_photo': 'Premium photo',
            'deliverable-type-content_premium_video': 'Premium video',
            'deliverable-type-youtube_short_form_video': 'Short video',
            'deliverable-type-youtube_long_form_video': 'YouTube video',
            'deliverable-type-youtube_short': 'Shorts',
            'content_standard_photo': 'UGC',
            'content_standard_video': 'UGC',
            'content_premium_photo': 'Studio look & feel',
            'content_premium_video': 'Studio look & feel',
            'youtube_short_form_video': '30s or less',
            'youtube_long_form_video': '30s - 5min',
            'youtube_short': '60s or less',
          },
          'product-to-feature': {
            placeholder: 'Describe the product creators should feature',
          },
          'content-mandatories': {
            title: 'Content Mandatories',
            placeholder: "do's and don'ts for content creation",
          },
          'posting-instructions': {
            title: 'Posting instructions',
            content:
              "Brand must review and approve your content and caption on platform before it's posted.",
          },
        },
        'creator-details': {
          'title': 'Creator Details',
          'followers-count': 'Follower count',
          'gender': 'Gender',
          'select-genders': 'All',
          'age': 'Age',
          'preferred-state': 'Preferred state (Optional)',
          'add-state': 'Add state',
          'min-age': 'Minimum age',
          'max-age': 'Maximum age',
          'ideal-creator-placeholder':
            'Describe the creator you are looking for',
        },
        'timeline': {
          'title': 'Timeline Overview',
          'label': 'Days',
          'label_one': 'Day',
          'label_other': 'Days',
          'content-creation-duration':
            'Materials should be submitted for review within:',
          'content-creation-duration-generated-brief':
            'Submit materials for review via the PopPays platform within {duration} days of receiving product/getting picked for the campaign.',
        },
        'additional-information': {
          'title': 'Additional Information',
          'brand-style-guide-placeholder':
            'Share content guidelines or links to brand style guides',
          'url-to-promote': 'URL to promote (Optional)',
          'url-to-promote-placeholder':
            '  Enter a full URL the creator will promote in a social feed',
          'eligibility-requirements-placeholder': 'Add additional requirements',
          'questions-for-creator': {
            'title': 'Questions for creator (Optional)',
            'add-question': 'Add question',
            'question-placeholder':
              'Ask about their style, ideas, or confirm any qualifications you require',
          },
        },
        'content-agreement': {
          'title': 'Content Agreement',
          'rights-to-content-created-title': 'Rights to Content Created',
          'rights-to-content-created-content':
            'Creator grants to the brand an exclusive and irrevocable license to modify and/or re-use (including in any generative AI tool) any content that is submitted and approved for this campaign for the following purposes:',
          'partial-footer-1':
            "Creator grants to the brand a license to modify and/or re-use ideas (recipes, DIY projects, etc.) submitted for the campaign, but brand has no rights to the Creator's blog post or the copy written for it.",
          'partial-footer-2-a':
            ' Creator also grants a license to Lightricks for purposes "{purpose1}" & "{purpose2}" (above).',
          'partial-footer-2-b':
            ' Creator also grants a license to Lightricks for purpose "{purpose}" (above).',
          'partial-footer-3':
            ' Click "Creator Terms and Conditions of Service" when applying to view full terms.',
          'footer-note':
            'Failure to comply with the brief can necessitate a re-post or result in the denial of payment, payment penalties, and/or can hurt your rating for the gig.',
          'time-period': 'Time period',
          'time-period-6': '6 months',
          'time-period-12': '12 months',
        },
        'terms-and-conditions': {
          'title': 'Terms and Conditions',
          'show-more': 'Show more',
          'show-less': 'Show less',
          'sub-sections': {
            'content-mandatories': {
              title: 'Content Mandatories',
              content:
                'Do not include any identifiable third party logos or brand markers, music, artwork, architecture, etc. unless you have the necessary permissions. If identifiable people are shown in the content you create for this campaign, you warrant that you have their permission to do so and that they understand how the content may be used by the brand.',
            },
            'age-availability': {
              title: 'Age Availability',
              content:
                'You warrant and represent that you are 13 years of age or above. If you are between 13 years of age and 18 years of age, in order to apply to participate in this Campaign:<br/>' +
                'a) You must have written permission from a parent or legal guardian;<br/>' +
                'b) A parent or legal guardian must have read the Campaign Brief;<br/>' +
                'c) You are able to provide the written permission granted by a parent or legal guardian, upon request; and<br/>' +
                'd) You are allowed under the applicable laws and regulations, both federal and state, to apply to participate in a Campaign.<br/>' +
                'If you are found to be in violation of this section, then you will be disqualified from this Campaign and will not be able to apply to any other Campaign.',
            },
            'product-to-feature-and-compensation-details': {
              title: 'Product to Feature and Compensation Details',
              content:
                'Product(s) will be gifted to selected participants solely in consideration for their services. In the event the participant fails to provide services, they agree to return the product(s).',
            },
            'caption-guidelines': {
              title: 'Caption Guidelines',
              content:
                'To disclose that you\'ve paired up with {brandName} for the post, must say something like "I\'m working with @brand to..." or "I\'ve partnered with {brandName} to…” or "In collaboration with @brand" or #ad or #sponsored.',
            },
            'posting-instructions-tiktok': {
              title: 'Posting Instructions',
              content:
                'Brand must review and approve your content and caption before you are permitted to post. Post during peak engagement hours. Follow these steps to post for TikTok: <a href="https://storage.googleapis.com/poppays-fonts/PDF/Visual-Posting-Instructions.pdf" rel="noopener noreferrer" target="_blank">Visual Posting Instructions.pdf</a>.',
            },
            'posting-instructions-instagram': {
              title: 'Posting Instructions',
              content:
                'Brand must review and approve your content and caption before you are permitted to post. Post during peak engagement hours. Non-expiring posts must remain live for at least 30 days. Your Instagram account must remain a Business/Creator account during participation of this campaign and for 30 days following completion of all work associated with it.',
            },
            'TT-music': {
              title: 'TT Music',
              content:
                '<ul><li>Reference the TikTok music library for commercially cleared music. You can find that here: <a href="https://cc.oceanengine.com/service/music/US/en/" rel="noopener noreferrer" target="_blank">Commercial Music Library: Pre-cleared Music For Organic Content</a>.</li>' +
                '<li>For creators on the TikTok Creator Marketplace, these tracks should all be included in a playlist called Promo+ that is made available to you for branded content purposes.</li>' +
                '<li>Please be advised that music in the general TikTok library is NOT cleared for commercial use.</li></ul>',
            },
            'content-approval': {
              title: 'Content Approval',
              content:
                'Brands must review and approve your photo(s) and video(s) before they are posted and any additional deliverables as outlined in the brief must also be reviewed and approved. Content uploaded to the platform may be downloaded by the brand/agency for the purpose of content review. Rights to the content are not granted until the content is approved. Rights to content are detailed in the brief.',
            },
            'timeline-overview': {
              title: 'Timeline Overview',
              content:
                'Timelines must be adhered to, per the campaign brief. Please communicate directly with the brand, or Lightricks, if you are unable to meet deadlines or have any issues with timelines.',
            },
            'FTC-guidelines': {
              title: 'FTC Guidelines',
              content:
                'Per the FTC\'s guidelines, all paid posts must be disclosed in a clear and conspicuous manner including #ad on all screens for video. Statements must represent your accurate experience with the product, and objective product claims must be substantiated. Full guidelines <a href="https://www.ftc.gov/system/files/ftc_gov/pdf/p204500_endorsement_guides_in_2023.pdf" rel="noopener noreferrer" target="_blank">here</a>.',
            },
          },
        },
      },
      'brief-automatically-saved':
        'This brief has been automatically saved to your drafts for later.',
      'submit-button': 'Launch Campaign',
      'errors': {
        'required-field': 'This is a required field',
        'select-at-least-one': 'Select at least one',
        'value-must-be-larger-than-zero': 'Value must be larger than 0',
        'min-larger-than-max': 'Minimum must be smaller than the maximum',
        'min-age-larger-than-max-age':
          'Minimum age must be smaller than maximum age',
        'add-a-description': 'Add a description',
        'image': 'Campaign image is required',
        'vertical-tags': 'Add at least one brand vertical',
        'campaign-paid-gig-deliverable-items': 'Add at least one deliverable',
        'campaign-hashtag': 'At least one hashtag is required',
        'url-to-promote': 'This URL is invalid',
        'days-to-post-after-deliverable-fulfilled':
          'Content creation duration is required',
        'content-agreement':
          'Content agreement requires at least one option to be selected',
      },
      'components': {
        'auto-fill-banner': {
          initial: {
            title: 'Define your creative vision with one click',
            subtitle: 'Fill the rest of your brief with the power of AI*.',
            button: 'Auto-fill',
          },
          completed: {
            title: 'Your AI brief is ready!',
            subtitle: {
              part1: 'Review it to make sure it’s perfect. ',
              part2: 'Use the AI to help with necessary tweaks.',
            },
          },
          disclaimer:
            '*AI is great, but it’s not perfect... Please read the <a href="https://static.lightricks.com/legal/BriefAI.pdf" target="_blank">AI Terms of Use</a> before continuing',
        },
        'guidelines-modal': {
          'title': 'Add your instructions (optional)',
          'subtitle':
            'Write anything else you want the AI to consider: brand guidelines, business goals, or selling points. You can also skip this and let us do it all for you.',
          'input-placeholder': 'Guidelines...',
          'button': 'Auto-fill',
        },
        'upgrade-banner': {
          title: "You've Hit Your Active Campaign Limit",
          subtitle: 'Upgrade to launch additional campaigns',
          button: 'Upgrade',
        },
      },
    },
    'file-selector': {
      'invalid-file-type': 'Please only include {types}',
      'invalid-file-size': 'File size must be less than {maxSize}',
    },
    'modal-contact-sales': {
      'h1': 'Upgrading your plan',
      'h2': 'Request a call from one of our helpful advisors to discover the right upgrade for you and your brand.',
      'button-primary': 'Request call',
      'button-secondary': 'Cancel',
      'success': "Hold tight, we'll be in touch shortly!",
      'error': 'Something went wrong. Please try again later.',
    },
    'modal-lever-limit': {
      'h1': 'You have reached your limit!',
      'h2': 'Upgrade your subscription to continue',
      'button-text': 'Subscribe',
      'not-org-owner':
        'Please consult your Organization Owner. They have permission to upgrade the subscription.',
    },
    'modal-terms-of-service': {
      'h1': 'Our Terms of Service have changed',
      'p': "By clicking 'Accept', you agree to the <a target='_blank' href='https://static.lightricks.com/legal/popular-pays-platform-terms-and-conditions.pdf' class='color--gray-medium-dark'>Popular Pays Terms</a> and the creator payment terms. <br> Learn about how we use and protect your data in our <a target='_blank' href='https://static.lightricks.com/legal/privacy-policy.pdf' class='color--gray-medium-dark'>Privacy Policy</a>",
      'button-text': 'Accept',
    },
    'modal-new-campaign-wizard': {
      'title': 'Choose campaign type',
      'campaign-types': {
        'brand-awareness': {
          title: 'Influencer campaign',
          description:
            'Influencers will share your product on their social media channels',
        },
        'content-creation': {
          title: 'Visual content',
          description:
            'Creators will produce photos & videos for your brand’s online presence',
        },
      },
    },
    'modal-subscription': {
      'content-left': {
        upgrade: {
          title: 'Upgrade to',
        },
      },
      'content-right': {
        entry: {
          'submit-label': {
            default: 'Continue',
            invoice: 'Contact sales',
          },
          'footer': {
            'note': 'Want something else?',
            'contact-sales': 'Contact Sales',
            'see-plans': 'See all plans',
          },
        },
        result: {
          'submit-label': {
            success: 'Done',
            failure: 'Go back',
          },
          'contact-sales': {
            success: {
              headline: 'We’ll be in touch shortly!',
              body: 'A sales representative will reach out in no time. Make sure to check your email',
            },
            failure: {
              headline: 'Something went wrong',
              body: 'Couldn’t send request',
            },
          },
        },
        payment: {
          disclaimer:
            'Brand is responsible for creator costs plus applicable service fees. All amounts shown are in USD',
          headline: {
            add: 'Add a credit card',
            existing: 'Use card on file',
          },
          button: {
            submit: 'Submit',
            edit: 'Edit',
          },
          action: {
            subscription: 'create a subscription',
            creditCard: 'add a credit card',
          },
          success: {
            headline: 'Upgrade Complete!',
            body: 'Subscription purchased successfully',
          },
          failure: {
            headline: 'Something went wrong',
            body: 'Couldn’t {action}',
          },
        },
      },
      'feature': {
        DashboardUserCount: {
          heading: 'Want to add more users?',
          subheading: 'Invite up to 5 users, and more with Pro',
        },
        ShareCreators: {
          heading: 'Want to share creators?',
          subheading: 'Share unlimited Creators and get a lot more with Pro',
        },
        OffPlatformReview: {
          heading: 'Collaborate on content review across your team',
          subheading: 'Get shareable links and more with Pro',
        },
        ShopifyIntegration: {
          heading: 'Upgrade to connect your Shopify store',
          subheading: 'Track links, sales, and more with Pro',
        },
        ActiveCampaignCount: {
          heading: 'Upgrade to launch more campaigns',
          subheading: 'Run 2 campaigns at the same time, and more with Pro',
        },
        ThreadsToggle: {
          heading: 'Collaborate 1 on 1 with Threads',
          subheading: 'Message with creators outside of a campaign with Pro',
        },
        BrandCount: {
          heading: 'Ready to onboard another brand?',
          subheading:
            'Contact Sales to increase the number of brands in your organization.',
        },
        CreatorsPerCampaign: {
          heading: 'Ready to partner with more creators?',
          subheading: 'Accept unlimited creators with Pro',
        },
        CollaborationCount: {
          heading: 'Partner with more creators',
          subheading: 'Accept unlimited creators with Pro',
        },
        generic: {
          heading: 'Level up your marketing game',
          subheading: 'Get more with {planLabel}',
        },
      },
      'plan': {
        price: 'Yours for only {price}/month',
      },
    },

    'new-campaign-options-container': {
      'awareness-campaign': 'Awareness Campaign',
      'content-campaign': 'Content Campaign',
      'ai-studio': 'Ai Studio',
    },
    'start-campaign-options-modal': {
      'ai-option': 'Generate your brief with AI',
      'create-your-brief': 'Create your brief',
    },
    'chat': {
      input: {
        placeholder: 'Type your request',
      },
      header: {
        title: 'Hey there',
        subtitle:
          'I’m Swift. I am powered by ai to help\n you throughout your workflow.',
      },
    },
    'tag-selector': {
      add: 'Add',
    },
    'select': {
      'selected': '{count} selected',
      'select-all': 'Select all',
      'select-all-that-apply': 'Select all that apply',
      'select-one': 'Select one',
    },
    'pricing': {
      'header': 'Choose a plan that’s right for you',
      'current-plan': 'Current Plan',
      'recommended': 'Free {days}-day trial!',
      'buttons': {
        'subscribe': 'Subscribe',
        'start-free-trial': 'Start free trial',
        'contact-sales': 'Contact Sales',
        'your-plan': 'Your Plan',
      },
      'marquee-section': {
        'title': 'Trusted by',
        'button-text': 'View Full feature comparison',
      },
      'pricing-tier': {
        'show-features': 'Show features',
        'hide-features': 'Hide features',
        'pay-period-monthly': '/month',
        'pay-period-monthly-mobile': '/Mo',
      },
      'modal-subscription': {
        'pricing-label': 'Yours for {price}/month',
      },
    },
    'list-drawer': {
      'heading': 'Lists',
      'new-list': 'Create new list',
      'all-creators': 'All creators',
      'list-menu': {
        edit: 'Edit name',
        delete: 'Delete list',
        share: 'Share list',
      },
      'shared-lists': 'Shared lists',
      'brand-lists': '{brandName} lists',
      'brand-lists-brand-name-fallback': 'Brand',
    },
    'invite-external-creators-modal': {
      'title': 'Invite external creators',
      'description':
        'Any creators that are not in our community will be sent an email to join.',
      'emails-placeholder': 'Paste email addresses here...',
      'invite': 'Invite',
      'choose-list': 'Choose list',
      'banner-title-single': 'Creator has been invited.',
      'banner-title-multiple': 'Creators have been invited.',
    },
    'remove-creator': {
      title: 'Remove {creatorName}',
      description:
        'Are you sure you want to remove this creator from this list?',
      cancel: 'Cancel',
      remove: 'Remove',
    },
    'move-creator': {
      'title': 'Change Lists',
      'add': 'Add',
      'back': 'Back',
      'save': 'Save',
      'create': 'Create new list',
      'organization-visibility':
        'Anyone in your organization can view this list.',
    },
    'delete-list': {
      title: 'Delete {name}?',
      description:
        'Are you sure you want to remove this list? This action can’t be undone.',
      cancel: 'Cancel',
      delete: 'Delete',
      success: 'List deleted successfully',
      error: 'Something went wrong.',
    },
    'edit-list': {
      'title': 'Edit List',
      'organization-visibility':
        'Anyone in your organization can view this list.',
      'save': 'Save',
    },
    'share-list': {
      description: 'What information would you like to share?',
      copy: 'Copy Link',
      copied: 'Link copied!',
      download: 'Save CSV',
      options: {
        overview: 'Overview',
        analytics: 'Analytics & Audience',
        posts: 'Posts',
      },
    },
    'preferences': {
      tabs: {
        account: 'Account',
        notifications: 'Notifications',
      },
      account: {
        'details': 'Details',
        'description': 'Update your name and login email address here.',
        'display-name': 'Your Display Name',
        'display-name-helper-text':
          'This could be your first name. We will display this name to creators.',
        'email': 'Your Email Address',
        'update': 'Update',
      },
      notifications: {
        'title': 'Email Notifications',
        'save-preferences': 'Save Preferences',
        'unsubscribe': 'Unsubscribe All',
        'unsubscribe-banner-title':
          'You have successfully unsubscribed from email notifications.',
        'save-preferences-banner-title':
          'Your preferences have been updated successfully.',
        'events': {
          'title': 'Events',
          'description':
            "We'll send you an email when any of these things happen:",
          'social-media-post-published': 'Post Live',
          'brand-campaign-launched': 'Campaign Live',
          'new-creator-message': 'New Message',
          'incomplete-brief-reminder': 'Brief Incomplete',
          'campaign-action-nudge': 'Next Steps',
          'application-email': 'Application Email',
          'content-submission': 'Content Submitted',
          'brand-digest': 'Campaign Digest',
        },
        'frequency': {
          'day': 'Every 24 hours',
          'mon-wed-fri': 'Every 48 hours',
          'week': 'Weekly',
          'opt-out': 'Opt out',
        },
      },
    },
    'profile-popup': {
      'followers': 'Followers',
      'eng-rate': 'Eng Rate',
      'avg-views': 'Avg Views',
      'avg-impressions': 'Avg Impressions',
      'avg-likes': 'Avg Likes',
      'completed-gigs': 'gigs completed',
    },
    'list-creators-table': {
      'header': {
        name: 'Creator Name',
        date: 'Date Added',
        status: 'Status',
        lists: 'Lists',
      },
      'change-list-popover': {
        'title': 'Change List',
        'shared-lists': 'Shared Lists',
        'brand-lists': '{brandName} lists',
        'brand-lists-brand-name-fallback': 'Brand',
      },
    },
    'list-action': {
      'add-list': {
        header: 'Create New List',
      },
      'edit-list': {
        header: 'Edit List',
      },
      'delete-list': {
        header: 'Delete List {label}',
      },
      'share-list': {
        header: 'Share',
      },
    },
    'total-results': {
      results: '{amount} results',
      for: 'for',
    },
    'creator-card': {
      'followers': 'Followers:',
      'eng-rate': 'Eng rate:',
      'add-to-list': 'Add to list',
      'invite-to-campaign': 'Invite to campaign',
      'invitation-sent': 'Invitation sent',
      'highly-rated': 'Highly rated',
      'new-talent': 'New talent',
      'overflow-menu': {
        'copy-email': 'Copy email',
        'invite-to-campaign': 'Invite to campaign',
        'add-to-list': 'Add to list',
        'start-thread': 'Start thread',
        'open-thread': 'Open thread',
        'error-message': 'Error loading menu.',
      },
    },
    'creator-profile': {
      'header': {
        'add-to-list-button': 'Add to list',
        'invite-to-campaign-button': 'Invite to campaign',
        'collaborate-button-tooltip': 'Send {creatorName} a message',
        'last-active': `Last active: {lastActiveDate}`,
        'completed-gigs': '{amount} gigs',
      },
      'tabs': {
        'overview': {
          'title': 'Overview',
          'about-me': 'About me',
          'show-more': 'Show more',
          'show-less': 'Show less',
          'portfolio': 'Portfolio',
          'application-questions': 'Application Questions & Answers',
        },
        'audience': {
          title: 'Audience',
          locked: 'See creator audience breakdown',
        },
        'analytics-and-audience': {
          'title': 'Analytics & Audience',
          'locked':
            'See advanced analytics and the authenticity of social accounts',
          'gender': 'Gender',
          'age-range': 'Age Range',
          'all': 'All',
          'men': 'Men',
          'women': 'Women',
          'top-cities': 'Top Cities',
          'top-countries': 'Top Countries',
          'followers': 'Followers',
          'follower-counts-vs-engagement-rage':
            'Follower Counts vs. Engagement Rate',
          'engagement-rate': 'Engagement Rate',
        },
        'posts': {
          'title': 'Posts',
          'locked': 'Search across creator posts from all networks',
          'search-placeholder':
            'Search by keywords, @mentions, #hashtag, emojis',
          'no-posts': 'Sorry, could not find any posts.',
        },
        'past-campaigns': {title: 'Past campaigns'},
        'notes': {
          'title': 'Notes',
          'locked': 'Record notes and organize creators in lists',
          'note-placeholder':
            'Write a note about this creator. You can always edit them later.',
          'cancel': 'Cancel',
          'save': 'Save changes',
        },
      },
      'identity-social-health': {
        'average-impressions-per-follower': 'Impression to Follower ratio',
        'average-views-per-follower': 'Views to Followers ratio',
        'followers': 'Followers',
        'paid-vs-organic': 'Sponsored vs Organic Posts',
        'follower-counts-vs-engagement': 'Follower Counts vs Engagement Rate',
        'impressions': 'Impressions',
        'views': 'Average Views',
        'no-data': 'Sorry, not enough data available.',
        'men': 'Men',
        'women': 'Women',
        'audience': {
          'item-header-1': 'Gender',
          'item-header-2': 'Age Range',
        },
        'locations': {
          'header-1': 'Top Cities',
          'header-2': 'Top Countries',
          'header-3': 'Top States',
        },
        'charts': {
          'followers': {
            'date': 'Date',
            'followers': 'Followers',
            'last-four-weeks': 'Last 4 weeks',
          },
          'followers-vs-engagement': {
            'followers-vs-engagement': 'Followers Counts vs Engagement Rate',
            'followers': 'Followers',
            'engagement-rate': 'Engagement Rate',
          },
          'impressions': {
            'impressions': 'Impressions',
            'average-impressions': 'Average Impressions',
          },
          'impressions-per-follower': {
            'impressions-to-follower-ratio': 'Impression to Follower ratio',
            'average-impressions-to-follower-ratio':
              'Avg Impressions to Follower ratio',
          },
          'paid-vs-organic': {
            'paid-vs-organic': 'Sponsored vs Organic Posts',
            'posts': 'Posts',
            'organic': 'Organic',
            'sponsored': 'Sponsored',
          },
        },
      },
      'tiktok-social-health': {
        'average-impressions-per-follower': 'Impression to Follower ratio',
        'average-views-per-follower': 'Views to Followers ratio',
        'followers': 'Followers',
        'paid-vs-organic': 'Sponsored vs Organic Posts',
        'follower-counts-vs-engagement': 'Follower Counts vs Engagement Rate',
        'impressions': 'Impressions',
        'views': 'Average Views',
        'no-data': 'Sorry, not enough data available.',
        'audience': {
          'item-header-1': 'Gender',
          'item-header-2': 'Age Range',
        },
        'locations': {
          'header-1': 'Top Cities',
          'header-2': 'Top Countries',
          'header-3': 'Top States',
        },
        'charts': {
          'followers': {
            'date': 'Date',
            'followers': 'Followers',
            'last-four-weeks': 'Last 4 weeks',
          },
          'followers-vs-engagement': {
            'followers-vs-engagement': 'Followers Counts vs Engagement Rate',
            'followers': 'Followers',
            'engagement-rate': 'Engagement Rate',
          },
          'views': {
            'views': 'Views',
            'average-views': 'Average Views',
          },
          'views-per-follower': {
            'views-to-follower-ratio': 'Views to Follower ratio',
            'average-views-to-follower-ratio': 'Avg Views to Follower ratio',
          },
          'paid-vs-organic': {
            'paid-vs-organic': 'Sponsored vs Organic Posts',
            'posts': 'Posts',
            'organic': 'Organic',
            'sponsored': 'Sponsored',
          },
        },
      },
      'saved-in-lists': {
        'saved-in-lists': 'Saved in lists',
      },
    },
    'toggleable-text': {
      'show-more': 'Show more',
      'show-less': 'Show less',
    },
    'social-network-identity-summary': {
      'lda-compliant': {
        lda: 'LDA',
        compliant:
          "Audience is LDA Compliant per Instagram's audience demographic data",
        probably_compliant:
          "Audience is likely LDA Compliant per Instagram's audience demographic data",
      },
      'health-scores': {
        'v-low-engagement': 'Very low engagement rate',
        'low-engagement': 'Low engagement rate',
        'below-avg-engagement': 'Below average engagement rate',
        'healthy-engagement': 'Healthy engagement rate',
        'v-low-impressions-to-followers':
          'Abnormally low impressions per followers',
        'low-impressions-to-followers': 'Low impressions per followers',
        'normal-impressions-to-followers': 'Normal impressions per followers',
        'high-impressions-to-followers': 'High impressions per followers',
        'v-high-impressions-to-followers':
          'Abnormally high impressions per followers',
        'one-social-account': 'One social account',
        'more-than-one-social-account': 'More than one social account',
        'several-social-accounts': 'Several social accounts',
        'no-collaborations': 'Has not completed a collaboration',
        'one-collaboration': 'Completed a collaboration',
        'several-collaborations': 'Completed several collaborations',
        'many-collaborations': 'Completed many collaborations',
        'a-lot-collaborations': 'Completed a lot of collaborations',
        'community-average': {
          below: '{percentage}% below the community average',
          above: '{percentage}% above the community average',
        },
        'audience-activity': 'Audience Activity',
        'engagement-rate': 'Engagement Rate',
        'impressions-to-followers': 'Impressions to Follower Ratio',
        'views-to-followers': 'View to Follower Ratio',
        'healthy': 'High',
        'healthy-sub': 'Creator has above average audience authenticity',
        'investigate': 'Medium',
        'investigate-sub':
          "Review creator's social profiles to determine the authenticity of their audience",
        'unhealthy': 'Low',
        'unhealthy-sub':
          "Review creator's social profiles to determine the authenticity of their audience",
        'unknown': 'Unknown reason',
        'unknown-sub': '',
        'badge': {
          high: 'High Authenticity',
          medium: 'Medium Authenticity',
          low: 'Low Authenticity',
          unknown: 'Unknown Authenticity',
        },
      },
      'profile-identity-summary': {
        'stats': {
          'followers': 'Followers',
          'likes': 'Avg Likes',
          'engagement-rate': 'Eng Rate',
          'impressions': 'Avg Post Impressions',
          'views': 'Avg Views',
          'reels': 'Avg Reels Plays',
          'stories': 'Avg Story Impressions',
        },
        'needs-relink-title': 'Nothing to see here... yet!',
        'needs-relink':
          "This creator hasn't given us permission to display their TikTok data yet... but as soon as they do, we will!",
        'demographics': {
          first: 'Top Country ({percentage}%)',
          second: 'Top Age Group ({percentage}%)',
          gender: {
            men: 'Mostly Men',
            women: 'Mostly Women',
            both: 'Men & Women',
            percentages: '{malePercentage}% Men, {femalePercentage}% Women',
          },
        },
      },
      'genders': {
        men: 'Men',
        women: 'Women',
      },
    },
    'lda-compliant-summary': {
      header: {
        probably_compliant: 'Likely LDA',
        compliant: 'LDA Compliant',
      },
      body: {
        compliant:
          "Audience is LDA Compliant per Instagram's audience demographic data",
        probably_compliant:
          "Audience is likely LDA Compliant per Instagram's audience demographic data",
      },
    },
    'chart-layout': {
      'empty-state': 'Sorry, not enough data available.',
    },
    'search-input': {
      placeholder: 'Search',
    },
    'identity-post': {
      'image-not-available': 'Image not available',
      'likes': 'Likes',
      'comments': 'Comments',
      'impressions': 'Impressions',
    },
    'profile-link': {
      'go-to-full-profile': 'Go to Full Profile',
    },
    'creator-vetting-report': {
      'background-checks-title': 'Google Search Risks',
      'background-checks-subtitle':
        'These search results are based on the creator’s name, so some entries may relate to others with a similar name.',
      'flagged-content-title': 'Flagged Content',
      'failed-content-title': 'Content Not Scanned',
      'components': {
        'profile-vetting-actions': {
          'showing': 'Showing {filtered} of {total}:',
          'severity': 'Severity',
          'severity-options': {
            all: 'All',
            high: 'High',
            medium: 'Medium',
            low: 'Low',
          },
        },
        'profile-vetting-breadcrumbs': {
          'back-to-vetting': 'Back to My Vetting List',
        },
        'profile-vetting-info': {
          'assign-status': 'Assign status',
        },
        'profile-vetting-tracking': {
          'updated': 'Updated:',
          'live-tracking': 'Live tracking:',
          'since-date': 'Since {date}',
        },
        'tracking-button': {
          'tracking-on': 'On',
          'tracking-off': 'Off',
          'options': {
            'on': 'Tracking on',
            'on-description':
              'We constantly scan new content from this creator and alert you if anything is flagged.',
            'off': 'Tracking off',
            'off-description': 'You won’t receive alerts for any future posts.',
          },
        },
        'vetting-status-button': {
          placeholder: 'Assign status',
          options: {
            'approved': 'Approved',
            'ready-for-review': 'Ready for Review',
            'declined': 'Declined',
          },
        },
        'profile-vetting-items-scanned': {
          'content-scanned': 'Content Scanned',
          'mins': 'Mins',
          'images': 'Images',
          'media': 'Media',
        },
        'profile-vetting-items-flagged': {
          'subtitle': 'Flagged Content',
          'category': 'Category',
          'risk-level': 'Risk Level',
          'posts-amount': '# Posts',
          'clear': 'Clear',
          'hide': 'Hide',
        },
        'profile-vetting-summary': {
          'vetting-summary': 'Creator Insights',
        },
        'scanned-platforms': {
          'title': 'Platforms Scanned',
          'updated-just-now': 'Updated just now',
          'now': 'Now',
          'creator-disconnected': '*Creator disconnected: {date}',
        },
        'profile-vetting-content-item': {
          'see-less': 'See less',
        },
        'failed-scan-note': {
          body: "There was an issue scanning some of this creator’s content. Rest assured, we're actively enhancing our technology to reduce future disruptions.",
        },
      },
    },
    'date-range-picker': {
      'start-date': 'Start Date',
      'end-date': 'End Date',
      'apply': 'Apply',
      'cancel': 'Cancel',
    },
  },
  services: {
    fortress: {
      errors: {
        'generate-otp': {
          default:
            "The email wasn't sent. An unknown error occurred. Please try again",
          400: "The email wasn't sent. An unknown error occurred. Please try again",
          429: 'Too many attempts. Please try again in ten minutes.',
          409: 'Something went wrong. Please <a href="https://popularpays.zendesk.com/hc/en-us/requests/new" rel="noopener noreferrer" target="_blank">contact us</a> to access your account.',
        },
        'verify-otp': {
          default:
            'Verification failed. Please wait a few minutes before you try again',
          401: 'Code not recognised. Please try again or request a new one.',
          400: 'Please request a new code and try again.',
          409: 'Something went wrong. Please <a href="https://popularpays.zendesk.com/hc/en-us/requests/new" rel="noopener noreferrer" target="_blank">contact us</a> to access your account.',
        },
      },
    },
  },
  shared: {
    'popular-pays': 'Popular Pays',
    'by-lightricks': 'by Lightricks',
  },
  utils: {
    'get-formatted-past-date': {
      'today': 'Today',
      'yesterday': 'Yesterday',
      'days-ago': '{days} days ago',
      'week-ago': '1 week ago',
      'weeks-ago': '{weeks} weeks ago',
      'month-ago': '1 month ago',
      'months-ago': '{months} months ago',
      'year-ago': '1 year ago',
      'years-ago': '{years} years ago',
    },
  },
  errors: {
    'something-went-wrong': 'Something went wrong',
    'please-try-again-or-come-back-later':
      'Please try again or come back later',
    'page-did-not-load': "Oops! Page Didn't Load",
    'try-refreshing-or-check-back-later':
      'Try refreshing or check back shortly.',
  },
};
